<template>
  <div class="cashier">
    <div class="header">
      <van-icon name="arrow-left" @click="returnFun" />支付中心</div>
    <div class="main">
      <div class="tip" v-if="orderInfo.OrderTitle.indexOf('文字')>=0">{{total>0?'支付':'下单'}}成功后，律师将在6秒内为您提供60分钟的图文咨询服务
      </div>
      <div class="tip" v-else-if="orderInfo.OrderTitle.indexOf('电话')>=0">{{total>0?'支付':'下单'}}成功后，律师将在10分钟内拨打您尾号
        {{user.MemberMoblie.substring(7,11)}} 的手机，为您提供60分钟的电话咨询服务</div>
      <div class="tip" v-else-if="orderInfo.OrderTitle.indexOf('视频')>=0">
        {{total>0?'支付':'下单'}}成功后，律师将在10分钟内向您发起视频通话，为您提供60分钟的视频咨询服务
      </div>
      <div class="tip" v-else-if="orderInfo.OrderTitle.indexOf('文书')>=0">{{total>0?'支付':'下单'}}成功后，律师将在约定时间向您交付文书</div>
      <div class="tip" v-else-if="orderInfo.OrderTitle.indexOf('律师函')>=0">{{total>0?'支付':'下单'}}成功后，律师将在约定时间向您交付律师函</div>
      <div class="tip"
        v-else-if="orderInfo.OrderTitle.indexOf('私人律师')>=0||orderInfo.OrderTitle.indexOf('私人婚姻律师')>=0||orderInfo.OrderTitle.indexOf('企业律师')>=0||orderInfo.OrderTitle.indexOf('维权律师')>=0||orderInfo.OrderTitle.indexOf('财产律师')>=0">
        {{total>0?'支付':'下单'}}成功后，律师将为您提供1对1专属咨询服务
      </div>

      <div class="tip" v-else-if="orderInfo.OrderTitle.indexOf('合同')>=0&&buymember">
        {{total>0?'支付':'下单'}}成功后，您可免费不限次下载任意合同模板
      </div>
      <div class="tip" v-else-if="orderInfo.OrderTitle.indexOf('合同')>=0&&!buymember">
        {{total>0?'支付':'下单'}}成功后，您可不限次下载当前合同模板
      </div>
      <div class="tip" v-else-if="orderInfo.OrderTitle.indexOf('案件')>=0">
        {{total>0?'支付':'下单'}}成功后，平台法务将在1个工作日内给你来电核实需求，根据您的要求安排本地律师</div>
      <div class="tip" v-else="orderInfo.OrderTitle.indexOf('会员')>=0">{{total>0?'支付':'下单'}}成功后，会员权益立即生效</div>
      <div class="info">
        <div class="serve">
          <img :src="icon" alt="">
          <div class="type">
            <div>
              {{orderInfo.OrderTitle=='文字咨询'?'图文咨询':orderInfo.OrderTitle.indexOf('合同模板')>=0?'合同模板':orderInfo.OrderTitle}}
            </div>
            <div v-if="orderInfo.OrderTitle.indexOf('合同模板')>=0">{{orderInfo.OrderTitle.substring(5)}}</div>
          </div>
          <div class="price">
            <div>¥ <span>{{price}}</span></div>
            <div>¥{{orderInfo.OrderNeedPay}}</div>
          </div>
        </div>
        <div class="yh">
          <img src="../assets/pay_icon_ticket.png" alt="">
          <span>优惠/折扣</span>
          <div class="right" @click="goYH" v-if="yh.CouponsName=='暂不使用优惠'">
            <span style="color:#aaa;">暂不使用优惠</span> <img src="../assets/me_btn_return.png" alt="">
          </div>
          <div class="right" v-else-if="yhList.length==0">
            <span style="color:#aaa;">暂无可用优惠券/折扣</span> <img src="../assets/me_btn_return.png" alt="">
          </div>
          <div class="right" v-else-if="yh.Price==0&&yh.YhGuid!=1">
            <span>-￥{{orderInfo.OrderNeedPay}}</span><img src="../assets/me_btn_return.png" alt="">
          </div>
          <div class="right" @click="goYH" v-else>
            <span>-￥{{yh.Price>Number(orderInfo.OrderNeedPay)?orderInfo.OrderNeedPay:yh.Price}}</span><img
              src="../assets/me_btn_return.png" alt="">
          </div>
        </div>
      </div>
      <div class="member" v-if="AssociatorState!='已开通'&&orderInfo.OrderTitle.indexOf('会员')<0&&!isNewUser"
        @click="buymemberFun">
        <div class="tag">限时特惠</div>
        <div>开通律兜会员，每月可省<span>250元</span></div>
        <div>
          <img src="../assets/pay_img_tuwenquan.png" alt="">
          <div>
            <div>¥ <span>{{member1.ProductMarketPrice}}</span> <span>¥{{member2.ProductMarketPrice}}</span></div>
            <div>1个月会员</div>
          </div>
          <img
            :src="buymember?require('../assets/icon_evaluate_selected.png'):require('../assets/icon_evaluate_normal.png')"
            alt="">
        </div>
        <div>再享<span>3大</span>超级权益</div>
        <div class="ad">
          <div class="item">
            <img src="../assets/pay_icon_free2.png" alt="">
            <div>1次特价</div>
            <div>电话咨询</div>
          </div>
          <div class="item">
            <img src="../assets/pay_icon_free3.png" alt="">
            <div>30天超值</div>
            <div>私人律师</div>
          </div>
          <div class="item">
            <img src="../assets/pay_icon_free4.png" alt="">
            <div>海量模板</div>
            <div>免费下载</div>
          </div>
        </div>
      </div>
      <div class="payType">
        <div>支付方式</div>
        <div class="item" @click="chosePay('wx')">
          <img src="../assets/pay_icon_wechat.png" alt=""><span>微信</span>
          <img
            :src="paytype=='wx'?require('../assets/icon_evaluate_selected.png'):require('../assets/icon_evaluate_normal.png')"
            alt="">
        </div>
        <div class="item" @click="chosePay('zfb')" v-if="!iswx">
          <img src="../assets/pay_icon_alipay.png" alt=""><span>支付宝</span>
          <img
            :src="paytype=='zfb'?require('../assets/icon_evaluate_selected.png'):require('../assets/icon_evaluate_normal.png')"
            alt="">
        </div>
      </div>
      <div class="need">
        <div>购买须知</div>
        <div v-if="orderInfo.OrderTitle.indexOf('文字')>=0">
          <div>1、非律师原因退款，平台将扣除30%违约金。</div>
        </div>
        <div v-if="orderInfo.OrderTitle.indexOf('电话')>=0">
          <div>1、为保护您的隐私信息，律师来电将显示网络号码（950开头）；</div>
          <div>2、60分钟内您可不限次数拨打律师电话；</div>
          <div>3、非律师原因退款，平台将扣除30%违约金。</div>
        </div>
        <div v-if="orderInfo.OrderTitle.indexOf('视频')>=0">
          <div>1、60分钟内您可不限次数发起视频通话；</div>
          <div>2、非律师原因退款，平台将扣除30%违约金。</div>
        </div>
        <div v-if="orderInfo.OrderTitle.indexOf('文书')>=0">
          <div>1、15天内免费修改，包含文字、电话沟通；</div>
          <div>2、非律师原因退款，平台将扣除30%违约金。</div>
        </div>
        <div v-if="orderInfo.OrderTitle.indexOf('律师函')>=0">
          <div>1、15天内免费修改，包含文字、电话沟通；</div>
          <div>2、非律师原因退款，平台将扣除30%违约金。</div>
        </div>
        <div
          v-if="orderInfo.OrderTitle.indexOf('私人律师')>=0||orderInfo.OrderTitle.indexOf('私人婚姻律师')>=0||orderInfo.OrderTitle.indexOf('企业律师')>=0||orderInfo.OrderTitle.indexOf('维权律师')>=0||orderInfo.OrderTitle.indexOf('财产律师')>=0">
          <div>1、非律师原因退款，平台将扣除30%违约金。</div>
        </div>
        <div v-if="orderInfo.OrderTitle.indexOf('案件')>=0">
          <div>1、案件确认需要支付3元作为案件确认金，此确认金跟律师代理费无关，律师代理费需跟律师沟通了解案情后额外支付。一旦确认案件，此确认金不予退还！</div>
        </div>
        <div v-if="orderInfo.OrderTitle.indexOf('合同')>=0">
          <div>1、您可在个人中心-合同模板中查看已购买的合同模板。</div>
        </div>
        <div v-if="orderInfo.OrderTitle.indexOf('会员')>=0">
          <div>1、会员权益包括：每月可享3张价值38元的图文咨询优惠券，1张价值38元的电话咨询优惠券，1张价值98元的私人律师优惠券，会员有效期内，您可免费不限次下载任意合同模板；</div>
          <div>2、开通会员后，当月会员优惠券立即到账，之后按会员周期发放优惠券；</div>
          <div>3、退款规则：若您未使用任何会员权益，可申请全额退款；若已使用会员权益，不支持退款。</div>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="btnDiv">
        <div class="left">
          <div class="cell">
            <div>合计 ¥<span>{{total}}</span><span v-if="buymember"> 含开通会员¥{{member1.ProductMarketPrice}}</span></div>
            <div>本单优惠{{totalYH}}元</div>
          </div>
        </div>
        <div class="right" @click="pay">{{total>0?'立即支付':'立即下单'}}</div>
      </div>
    </div>
    <van-dialog v-model="dialogVisiblePay" confirm-button-text="已完成" cancel-button-text="放弃支付"
      confirm-button-color="#21D0C8" class="confirmDialog" @confirm="checkPay" @cancel="cancelPay" title=""
      show-cancel-button>
      <div>请确认支付是否已完成</div>
    </van-dialog>
  </div>
</template>
<script>
  import {
    GetCashCouponsList,
    CommonPay,
    GetPaymentResult,
    QuickAssociatorOrder,
    GetMemberAssociatorPCList,
    notifyurl,
    returnurl,
    returnurlwx,
    paysystem,
    WxJsApiParam,
    GetCasePrice
  } from '@/api'
  var payTimer = null
  var loading
  export default {
    name: 'cashier',
    data() {
      return {
        yh: {},
        yhList: [],
        paytype: 'wx',
        buymember: false,
        user: null,
        orderInfo: {
          OrderTitle: '',
          OrderNeedPay: 0,
        },
        price: 0,
        total: 0,
        memberYH: [],
        memberOrder: {
          OrderNeedPay: 0
        },
        member1: {
          ProductMarketPrice: 0
        },
        member2: {
          ProductMarketPrice: 0
        },
        casePrice: 0,
        icon: '',
        AssociatorState: '',
        isNewUser: false,
        totalYH: 0,
        dialogVisiblePay: false,
        iswx: false
      }
    },
    watch: {
      buymember(newVal) {
        if (newVal) {
          this.watchMember('会员')
        } else {
          if (this.orderInfo.OrderTitle == '文字咨询' && this.orderInfo.LawyerGuid == null) {
            this.yhList.splice(0, 3)
            this.choseYH(0)
          } else if (this.orderInfo.OrderTitle == '电话咨询' && this.orderInfo.LawyerGuid == null) {
            this.yhList.splice(0, 1)
            this.choseYH(0)
          } else if (this.orderInfo.OrderTitle == '私人律师' && this.orderInfo.LawyerGuid == null) {
            this.yhList.splice(0, 1)
            this.choseYH(0)
          } else if (localStorage.getItem('CaseGuid') && !localStorage.getItem('payOrder')) {
            this.price = this.orderInfo.OrderNeedPay
            this.total = this.price
            this.getPay()
          } else if (this.orderInfo.OrderTitle.indexOf('合同模板') >= 0) {
            this.price = this.orderInfo.OrderNeedPay
            this.total = this.price
            this.getPay()
          } else {
            this.choseYH(0)
            this.itemyh()
          }
        }
      }
    },
    mounted() {
      loading = this.$toast.loading({
        duration: 0,
        forbidClick: true,
      });
      if ((this.$route.query.payreturn && this.$route.query.payreturn == 1) || this.$route.query.is_success) {
        this.dialogVisiblePay = true
        this.sucessFun(localStorage.getItem('payCustomerId'))
      }
      _hmt.push(['_trackPageview', '/cashier'])
      this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
      this.isMember().then(res => {
        this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        this.AssociatorState = this.user.AssociatorState
      })
      if (this.$route.query.first && this.$route.query.first == 1) {
        this.isNewUser = true
      }
      this.iswx = this.isWeiXin()
      var title = ''
      if (localStorage.getItem('CaseGuid') && !localStorage.getItem('payOrder')) {
        this.orderInfo.OrderTitle = '案件确认'
        GetCasePrice().then(res => {
          if (res.state == 0) {
            this.casePrice = res.data
            this.orderInfo.OrderNeedPay = res.data
            this.price = res.data
            this.total = res.data
            this.icon = require('../assets/pay_img_dengji.png')
            loading.close();
          }
        })

      } else {
        this.orderInfo = JSON.parse(localStorage.getItem('payOrder'))
        if (this.orderInfo.OrderTitle.indexOf('文字') >= 0) {
          title = '文字'
          this.icon = require('../assets/pay_img_picture.png')
        } else if (this.orderInfo.OrderTitle.indexOf('电话') >= 0) {
          title = '电话'
          this.icon = require('../assets/pay_img_phone.png')
        } else if (this.orderInfo.OrderTitle.indexOf('视频') >= 0) {
          title = '视频'
          this.icon = require('../assets/pay_img_video.png')
        } else if (this.orderInfo.OrderTitle.indexOf('私人律师') >= 0) {
          title = '私人律师'
          this.icon = require('../assets/pay_icon_lawyer.png')
        } else if (this.orderInfo.OrderTitle.indexOf('私人婚姻律师') >= 0 || this.orderInfo.OrderTitle.indexOf('企业律师') >= 0 ||
          this.orderInfo.OrderTitle.indexOf('维权律师') >= 0 || this.orderInfo.OrderTitle.indexOf('财产律师') >= 0) {
          this.icon = require('../assets/pay_icon_lawyer.png')
        } else if (this.orderInfo.OrderTitle.indexOf('文书') >= 0) {
          title = '文书'
          this.icon = require('../assets/pay_img_wirte.png')
        } else if (this.orderInfo.OrderTitle.indexOf('会员') >= 0) {
          title = '会员'
          this.icon = require('../assets/pay_icon_vip.png')
        } else {
          title = this.orderInfo.OrderTitle
        }
        if (this.orderInfo.OrderTitle.indexOf('合同模板') >= 0) {
          this.icon = require('../assets/pay_img_moban.png')
        } else if (this.orderInfo.OrderTitle.indexOf('律师函') >= 0) {
          this.icon = require('../assets/pay_img_letter.png')
        }

      }
      GetMemberAssociatorPCList({
        "memberGuid": this.user.MemberGuid
      }).then(res => {
        if (res.state == 0) {
          for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].ProductTitle == '首月特惠') {
              this.member1 = res.data[i]
            }
            if (res.data[i].ProductTitle == '1个月会员') {
              this.member2 = res.data[i]
            }
          }
          if (this.$route.query.member && this.memberOrder.OrderNeedPay == 0) {
            var data = {
              "productGuid": this.member1.ProductGuid,
              "memberGuid": this.user.MemberGuid,
              "orderFrom": localStorage.getItem('orderfrom'),
              "otherOrderOrderGuid": this.orderInfo.OrderGuid,
            }
            QuickAssociatorOrder(data).then(res => {
              if (res.state == 0) {
                this.memberOrder = res.data
                if ((localStorage.getItem('CaseGuid') && !localStorage.getItem('payOrder')) || this.orderInfo
                  .OrderTitle.indexOf('合同模板') >= 0) {
                  this.price = this.orderInfo.OrderNeedPay
                  this.total = this.orderInfo.OrderNeedPay
                  this.buymember = this.$route.query.member && this.$route.query.member == 1 ? true : false
                  // this.getPay()
                  loading.clear();
                } else {
                  this.getYH(title, 'default')
                }
              }
            })
          } else {
            if ((localStorage.getItem('CaseGuid') && !localStorage.getItem('payOrder')) || this.orderInfo.OrderTitle
              .indexOf('合同模板') >= 0) {
              this.price = this.orderInfo.OrderNeedPay
              this.total = this.orderInfo.OrderNeedPay
              this.buymember = this.$route.query.member && this.$route.query.member == 1 ? true : false
              // this.getPay()
              loading.clear();
            } else {
              this.getYH(title, 'default')
            }
          }

        }
      })
      window.addEventListener("popstate", function (e) {
        clearInterval(payTimer)
        if (e.target.location.pathname != '/cashier') {
          localStorage.removeItem('choseyh')
        }
      }, false)
    },
    beforeRouteLeave(to, from, next) {
      clearInterval(payTimer)
      next()
    },
    methods: {
      sucessFun(id) {
        clearInterval(payTimer)
        payTimer = setInterval(res => {
          GetPaymentResult({
            "payCustomerId": id
          }).then(res => {
            if (res.state == 0) {
              if (res.data == 1) {
                clearInterval(payTimer)
                this.returnPage()
              }
            }
          })
        }, 1000)

      },
      checkPay() {
        this.dialogVisiblePay = false
        GetPaymentResult({
          "payCustomerId": localStorage.getItem('payCustomerId')
        }).then(res => {
          if (res.state == 0) {
            if (res.data == 1) {
              this.returnPage()
            } else {
              this.$toast('支付出错，请重试')
            }
          } else {
            this.$toast('支付出错，请重试')
          }
        })
        clearInterval(payTimer)
      },
      cancelPay() {
        clearInterval(payTimer)
        this.dialogVisiblePay = false
        this.$router.push({
          query: {
            'payreturn': 0
          }
        })
      },
      goYH() {
        localStorage.setItem('yh', JSON.stringify(this.yh))
        localStorage.setItem('yhlist', JSON.stringify(this.yhList))
        this.$router.push('/selectCoupon')
      },
      getYH(type, from) {
        var direction = 1
        if ((this.$route.query.from && this.$route.query.from == 'ask') || (localStorage.getItem('cashier_from') &&
            localStorage.getItem('cashier_from') == 'ask')) {
          direction = 0
        }
        var data = {
          "memberGuid": this.user.MemberGuid,
          "type": type,
          "direction": direction
        }
        GetCashCouponsList(data).then(res => {
          loading.clear();
          if (res.state == 0) {
            this.yhList = res.data
            if (res.data.length == 0) {
              this.price = this.orderInfo.OrderNeedPay
              this.total = this.price
            } else {
              var p = (Number(this.orderInfo.OrderNeedPay) * 1000 - Number(res.data[0].Price) * 1000) / 1000
              this.price = p > 0 ? p : 0
              this.total = this.price
              this.yh = res.data[0]

            }

            if (!(from == 'default' && this.$route.query.member)) {
              // this.getPay()
              this.watchMember('default')
            } else {
              this.buymember = this.$route.query.member == 0 ? false : true
            }
          }
        })
      },
      watchMember(type) {

        if (this.buymember) {
          if (localStorage.getItem('CaseGuid') && !localStorage.getItem('payOrder')) { //案件
            if (this.memberOrder.OrderNeedPay == 0) {
              var data = {
                "productGuid": this.member1.ProductGuid,
                "memberGuid": this.user.MemberGuid,
                "orderFrom": localStorage.getItem('orderfrom'),
                "otherOrderOrderGuid": this.orderInfo.OrderGuid,
              }
              QuickAssociatorOrder(data).then(res => {
                if (res.state == 0) {
                  this.memberOrder = res.data
                  this.getPay()
                }
              })
            } else {
              this.getPay()
            }
            this.itemyh()
          } else if (this.orderInfo.OrderTitle.indexOf('合同模板') >= 0) {
            this.price = 0
            if (this.memberOrder.OrderNeedPay == 0) {
              var data = {
                "productGuid": this.member1.ProductGuid,
                "memberGuid": this.user.MemberGuid,
                "orderFrom": localStorage.getItem('orderfrom'),
                "otherOrderOrderGuid": this.orderInfo.OrderGuid,
              }
              QuickAssociatorOrder(data).then(res => {
                if (res.state == 0) {
                  this.memberOrder = res.data
                  this.total = Number(this.member1.ProductMarketPrice)
                  this.getPay()
                }
              })
            } else {
              this.total = Number(this.member1.ProductMarketPrice)
              this.getPay()
            }
            this.itemyh()
          } else {
            if (localStorage.getItem('cashier_from') && localStorage.getItem('cashier_from') == 'ask') {
              var date1 = new Date();
              var date2 = new Date(date1);
              date2.setDate(date1.getDate() + 30);
              var EndDate = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate() + ' ' + date1
                .getHours() + ':' + date1.getMinutes() + ':' + date1.getSeconds()
              if (this.orderInfo.OrderTitle == '文字咨询') {
                var obj1 = {
                  CouponType: "会员",
                  CouponsName: "（会员专享）图文咨询优惠券",
                  CouponsType: "",
                  EndDate: EndDate,
                  Price: 38,
                  UseState: "",
                  YhGuid: "4",
                }
                var obj2 = {
                  CouponType: "会员",
                  CouponsName: "（会员专享）图文咨询优惠券",
                  CouponsType: "",
                  EndDate: EndDate,
                  Price: 38,
                  UseState: "",
                  YhGuid: "5",
                }
                var obj3 = {
                  CouponType: "会员",
                  CouponsName: "（会员专享）图文咨询优惠券",
                  CouponsType: "",
                  EndDate: EndDate,
                  Price: 38,
                  UseState: "",
                  YhGuid: "6",
                }
                this.memberYH = []
                this.memberYH.push(obj1)
                this.memberYH.push(obj2)
                this.memberYH.push(obj3)
                this.yhList = this.memberYH.concat(this.yhList)
                this.yh = obj1
              } else if (this.orderInfo.OrderTitle == '电话咨询') {
                var obj1 = {
                  CouponType: "会员",
                  CouponsName: "（会员专享）电话咨询优惠券",
                  CouponsType: "",
                  EndDate: EndDate,
                  Price: 38,
                  UseState: "",
                  YhGuid: "4",
                }
                this.memberYH = []
                this.memberYH.push(obj1)
                this.yhList = this.memberYH.concat(this.yhList)
                this.yh = obj1
              } else if (this.orderInfo.OrderTitle.indexOf('私人律师') >= 0) {
                var obj1 = {
                  CouponType: "会员",
                  CouponsName: "（会员专享）私人律师优惠券",
                  CouponsType: "",
                  EndDate: EndDate,
                  Price: 98,
                  UseState: "",
                  YhGuid: "4",
                }
                this.memberYH = []
                this.memberYH.push(obj1)
                this.yhList = this.memberYH.concat(this.yhList)
                this.yh = obj1
              }

            } else {
              this.itemyh()
            }

            if (this.memberOrder.OrderNeedPay == 0) {
              var data = {
                "productGuid": this.member1.ProductGuid,
                "memberGuid": this.user.MemberGuid,
                "orderFrom": localStorage.getItem('orderfrom'),
                "otherOrderOrderGuid": this.orderInfo.OrderGuid,
              }
              QuickAssociatorOrder(data).then(res => {
                if (res.state == 0) {
                  this.memberOrder = res.data
                  this.choseYH(0, 'nopay')
                  this.itemyh()
                }
              })
            } else {
              this.choseYH(0, 'nopay')
              this.itemyh()
            }
          }
        } else {
          this.itemyh()
        }


      },
      itemyh() {
        if (localStorage.getItem('choseyh')) {
          this.yh = JSON.parse(localStorage.getItem('choseyh'))
          var p = (Number(this.orderInfo.OrderNeedPay) * 1000 - Number(this.yh.Price) * 1000) / 1000
          this.price = p > 0 ? p : 0
          this.total = this.buymember ? (Number(this.price) * 1000 + Number(this.member1.ProductMarketPrice) * 1000) /
            1000 : this.price
          if (this.yh.Price == 0 && this.yh.YhGuid != 1) {
            this.total = 0
          }
          this.getPay()
        } else {
          this.getPay()
        }
      },
      getPay() {
        if (this.orderInfo.OrderTitle.indexOf('合同模板') >= 0 && this.buymember) {
          this.totalYH = this.orderInfo.OrderNeedPay
        } else {
          var p = (Number(this.orderInfo.OrderNeedPay) * 1000 - Number(this.yh.Price) * 1000) / 1000
          this.totalYH = this.yh.Price ? p > 0 ? this.yh.Price : this
            .orderInfo.OrderNeedPay : 0
          if (this.yh.Price == 0 && this.yh.YhGuid != 1) {
            this.totalYH = this.orderInfo.OrderNeedPay
          }
        }
        if (this.buymember) {
          var p = 0
          if (Number(this.member2.ProductMarketPrice) - Number(this.member1.ProductMarketPrice) > 0) {
            p = (Number(this.member2.ProductMarketPrice) * 1000 - Number(this.member1.ProductMarketPrice) * 1000) / 1000
          }
          this.totalYH = Number(this.totalYH) + p
          if (localStorage.getItem('CaseGuid') && !localStorage.getItem('payOrder')) {
            this.total = (Number(this.member1.ProductMarketPrice) * 1000 + Number(this.orderInfo.OrderNeedPay) * 1000) /
              1000
          }
        }
      },
      pay() {
        _hmt.push(['_trackEvent', 'click', '立即支付', 'mob']);
        if (this.total == 0) {
          var yhGuid = JSON.stringify(this.yh) == '{}' || this.yh.YhGuid == 1 ?
            '' : this.yh.YhGuid
          if (this.yh.CouponType == '会员') {
            yhGuid = '99999999-9999-9999-9999-999999999999'
          }
          var paytype = '04'
          if (this.orderInfo.OrderTitle.indexOf('会员') >= 0) {
            paytype = '20'
          } else if (this.orderInfo.OrderTitle.indexOf('合同模板') >= 0) {
            paytype = '21'
          } else if (this.orderInfo.OrderTitle.indexOf('案件') >= 0) {
            paytype = '09'
          } else if (this.buymember) {
            paytype = '22'
          }
          var data = {
            "orderGuid": this.orderInfo.OrderGuid,
            "yhGuid": yhGuid,
            "money": this.orderInfo.OrderNeedPay,
            "paytype": paytype,
            "bankname": this.paytype,
            "webremark": this.yh.CouponType == '首单特惠' ? this.orderInfo.OrderTitle + '-首单特惠' : this.orderInfo
              .OrderTitle,
            "pc": ""
          }
          CommonPay(data).then(res => {
            if (res.state == 0) {
              this.$toast('支付成功')
              setTimeout(() => {
                this.returnPage()
              }, 1000)
            }
          })
        } else {
          var yhGuid = JSON.stringify(this.yh) == '{}' || this.yh.YhGuid == 1 || this.yh.YhGuid == 2 || this.yh
            .YhGuid == 3 || this.yh.CouponType == '首单特惠' ?
            '' : this.yh.YhGuid
          if (this.yh.CouponType == '会员') {
            yhGuid = '99999999-9999-9999-9999-999999999999'
          }
          var money = this.orderInfo.OrderNeedPay
          var paytype = '04',
            orderGuid = this.orderInfo.OrderGuid
          if (this.orderInfo.OrderTitle.indexOf('会员') >= 0) {
            paytype = '20'
          } else if (this.orderInfo.OrderTitle.indexOf('合同模板') >= 0) {
            paytype = '21'
          } else if (this.orderInfo.OrderTitle.indexOf('案件') >= 0) {
            paytype = '09'
            orderGuid = localStorage.getItem('CaseGuid')
            yhGuid = ''
          }
          if (this.orderInfo.OrderTitle.indexOf('合同模板') >= 0 && this.buymember) {
            money = Number(this.orderInfo.OrderNeedPay)
            paytype = '22'
          } else if (this.buymember) {
            paytype = '22'
            orderGuid = this.memberOrder.OrderGuid
            money = (Number(this.member1.ProductMarketPrice) * 1000 + Number(this.orderInfo.OrderNeedPay) * 1000) / 1000
          }
          var pc = 'h5'
          if (this.isWeiXin()) {
            pc = 'wxh5'
          }
          var data = {
            "orderGuid": orderGuid,
            "yhGuid": yhGuid,
            "money": money,
            "paytype": paytype,
            "bankname": this.paytype,
            "webremark": this.yh.CouponType == '首单特惠' ? this.orderInfo.OrderTitle + '-首单特惠' : this.orderInfo
              .OrderTitle,
            "pc": pc,
            "url": "https://consultmob.ilvdo.net",
            "pgurl": localStorage.getItem('zgt') ? (window.location.href + '&payreturn=1') : ''
          }
          CommonPay(data).then(res => {
            if (res.state == 0) {
              localStorage.setItem('payCustomerId', res.data.payCustomerId)
              if (localStorage.getItem('zgt')) {
                let params = {
                  merId: res.data.signModel.merId,
                  orderSn: res.data.signModel.orderSn,
                  orderNo: res.data.signModel.orderNo,
                  tradeAmount: res.data.signModel.tradeAmount,
                  verNo: res.data.signModel.verNo,
                  attach: res.data.signModel.attach,
                  signType: res.data.signModel.signType,
                  sign: res.data.signModel.sign,
                  timestamp: res.data.signModel.timestamp,
                  pgurl: res.data.signModel.pgurl,
                }
                this.dialogVisiblePay = true
                wx.miniProgram.navigateTo({
                  url: '/packageZgt/pages/common_pay_center?params=' + encodeURIComponent(JSON.stringify(
                    params))
                })
              } else if (this.paytype == 'wx') {
                if (this.isWeiXin()) {
                  if (localStorage.getItem('wxOpenid')) {
                    this.callpay(this.orderInfo.OrderTitle, res.data.needpaymoney, res.data.payCustomerId)
                  } else {
                    this.$toast('您还没有微信授权，暂时无法进行微信支付')
                  }
                } else {
                  var url = JSON.parse(res.data.bankname).mweb_url
                  var ret = returnurlwx + '&payCustomerId=' + res.data.payCustomerId
                  location.href = url + '&redirect_url=' + encodeURIComponent(ret)
                }
              } else {
                this.zfbPay(res.data.payCustomerId)
              }

            }
          })
        }
      },
      callpay(subjectname, orderneedpay, payid) {

        console.log({
          subjectname: subjectname,
          orderneedpay: orderneedpay,
          payid: payid
        })
        // debugger
        if (!typeof WeixinJSBridge == "undefined") {

          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', this.jsApiCall, false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', this.jsApiCall);
            document.attachEvent('onWeixinJSBridgeReady', this.jsApiCall);
          }
        } else {

          if (subjectname == null || orderneedpay == null || payid == null) {
            this.$toast('下单失败');
            return false;
          }
          var postData = {
            "subjectName": subjectname,
            "orderneedPpay": orderneedpay,
            "productID": this.orderInfo.ProductGuid,
            "payID": payid,
            "openID": localStorage.getItem('wxOpenid')
          }
          WxJsApiParam(postData).then(res => {

            if (res.state == 0) {

              this.jsApiCall(res.data.result);
            }
          })

        }
      },
      //调用微信JS api 支付
      jsApiCall(wxJsApiParam) {
        var that = this;
        var json = JSON.parse(wxJsApiParam)
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest',
          json, //josn串
          function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") { //支付成功后点击完成页面跳转
              window.location.href = returnurl;
            } else {
              that.dialogVisiblePay = true
            }
          });
      },
      isWeiXin() {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
          return true;
        } else {
          return false;
        }
      },
      zfbPay(payCustomerId) {
        this.zfbUrl = paysystem + '?payid=' + payCustomerId + "&totalpay=" + this.total + "&notifyurl=" + notifyurl +
          "&returnurl=" + returnurlwx + "&subjectname=" + this.orderInfo.OrderTitle + "&app_pay=";
        location.href = this.zfbUrl
      },
      buymemberFun() {
        this.buymember = !this.buymember
        if (!this.buymember) {
          _hmt.push(['_trackEvent', 'click', '取消开通会员', 'mob']);
        } else {
          _hmt.push(['_trackEvent', 'click', '开通会员', 'mob']);
        }
      },
      returnPage() {
        if (this.orderInfo.OrderTitle.indexOf('会员') >= 0) {
          this.isMember().then(res => {
            this.$router.replace('/member')
          })
        } else if (this.orderInfo.OrderTitle.indexOf('合同模板') >= 0) {
          this.$router.replace('/contractDetail?guid=' + localStorage.getItem('cashier_contract'))
        } else if (this.orderInfo.OrderTitle.indexOf('案件') >= 0) {
          this.$router.replace('/chatroom?lawyerThirdId=' + localStorage.getItem('chat_lawyer'))
        } else if (this.orderInfo.LawyerThirdId != '' && this.orderInfo.LawyerThirdId != null) { //定向
          this.$router.replace('/chatroom?lawyerThirdId=' + localStorage.getItem('chat_lawyer'))
        } else {
          // localStorage.setItem('chatroomLawyer', '')
          this.$router.replace('/wait')
        }
      },
      choseYH(index, type) {
        // clearInterval(payTimer)
        if (this.yhList.length > 0) {
          this.yh = this.yhList[index]
          var p = (Number(this.orderInfo.OrderNeedPay) * 1000 - Number(this.yh.Price) * 1000) / 1000
          this.price = p > 0 ? p : 0
        } else {
          this.price = this.orderInfo.OrderNeedPay
          this.yh = []
        }

        if (this.buymember) {
          this.total = (Number(this.price) * 1000 + Number(this.member1.ProductMarketPrice) * 1000) / 1000
        } else {
          this.total = this.price
          if (this.yh.Price == 0 && this.yh.YhGuid != 1) {
            this.total = this.orderInfo.OrderNeedPay
          }
        }
        this.getPay()
      },
      returnFun() {
        this.$router.back()
      },
      chosePay(type) {
        this.paytype = type
      },
      getNowTime() {
        let dateTime
        let yy = new Date().getFullYear()
        let mm = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) :
          (new Date().getMonth() + 1)
        let dd = new Date().getDate() < 10 ? '0' + new Date().getDate() :
          new Date().getDate()
        let hh = new Date().getHours() < 10 ? '0' + new Date().getHours() :
          new Date().getHours()
        let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() :
          new Date().getMinutes()
        let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() :
          new Date().getSeconds()
        dateTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
        console.log(dateTime)
        return dateTime
      },
    },
    beforeRouteLeave(to, form, next) {
      console.log(to)
      localStorage.removeItem('choseyh')
      next()
    },
    components: {

    }
  }

</script>

<style scoped lang="scss">
  .cashier {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #F5F5F8;

    .header {
      background: #21D0C8;
      text-align: center;
      height: 80px;
      line-height: 80px;
      font-size: 30px;
      font-weight: bold;
      color: #fff;
      position: relative;

      .van-icon {
        font-size: 36px;
        position: absolute;
        top: 25px;
        left: 30px;
      }
    }

    .main {
      background: #F5F5F8 linear-gradient(0deg, #F0F0F0 0%, #21D0C8 100%) no-repeat;
      background-size: 100% 300px;
      padding: 20px 30px;
      box-sizing: border-box;
      height: calc(100% - 200px);
      overflow: auto;

      .tip {
        padding: 20px 30px;
        background: #FDF6E0;
        border-radius: 20px;
        font-size: 30px;
        color: #FF8400;
        margin-bottom: 30px;
      }

      .info {
        padding: 45px 40px;
        background: #FFFFFF;
        border-radius: 20px;
        margin-bottom: 30px;

        .serve {
          margin-bottom: 60px;

          img {
            width: 90px;
            display: inline-block;
            margin-right: 21px;
          }

          .type {
            display: inline-block;
            vertical-align: top;

            >div {
              font-size: 36px;
              font-weight: 800;
              color: #333333;

            }

            >div:nth-child(2) {
              font-size: 24px;
              color: #333333;
              width: 257px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .price {
            float: right;
            position: relative;
            top: -8px;

            >div:nth-child(1) {
              font-size: 24px;
              font-weight: 800;
              color: #333333;
              margin-bottom: 16px;

              span {
                font-size: 42px;
              }
            }

            >div:nth-child(2) {
              font-size: 24px;
              text-decoration: line-through;
              color: #AAAAAA;
              text-align: right;
            }
          }
        }

        .yh {
          >img {
            width: 36px;
            margin-right: 14px;
            vertical-align: middle;
          }

          >span {
            font-size: 30px;
            color: #333333;
            vertical-align: middle;
          }

          .right {
            float: right;
            font-size: 26px;
            font-weight: bold;
            color: #E93939;

            span {
              font-size: 30px;
              margin-right: 10px;
              vertical-align: middle;
            }

            img {
              width: 16px;
              height: 28px;
              vertical-align: middle;
              display: inline-block;
            }
          }
        }
      }

      .payType {
        padding: 30px 40px;
        background: #FFFFFF;
        border-radius: 20px;
        margin-bottom: 30px;

        >div:nth-child(1) {
          font-size: 30px;
          font-weight: 800;
          color: #333333;
          margin-bottom: 30px;
        }

        .item:last-child {
          margin-top: 40px;
        }

        .item {
          >img:nth-child(1) {
            width: 38px;
            margin-right: 15px;
            vertical-align: middle;
          }

          span {
            font-size: 30px;
            color: #333333;
            vertical-align: middle;
          }

          >img:last-child {
            width: 40px;
            float: right;
          }
        }
      }

      .need {
        padding: 30px 40px;
        background: #FFFFFF;
        border-radius: 20px;

        >div:nth-child(1) {
          font-size: 30px;
          font-weight: 800;
          color: #333333;
          margin-bottom: 20px;
        }

        >div:nth-child(2) {
          font-size: 28px;
          color: #333333;

          >div:not(:last-child) {
            margin-bottom: 30px;
          }
        }
      }

      .member {
        margin-bottom: 30px;
        position: relative;
        padding: 80px 40px 50px;
        background: #FFFFFF;
        border-radius: 20px;

        .tag {
          width: 140px;
          height: 50px;
          background: #F24537;
          border-radius: 20px 0px 25px 0px;
          font-size: 26px;
          font-weight: 800;
          color: #FFFFFF;
          text-align: center;
          line-height: 50px;
          position: absolute;
          top: 0;
          left: 0;
        }

        >div:nth-child(4),
        >div:nth-child(2) {
          font-size: 30px;
          color: #333333;
          margin-bottom: 27px;

          span {
            color: #F24537;
          }
        }

        >div:nth-child(3) {
          margin-bottom: 39px;
          position: relative;

          >img:nth-child(1) {
            width: 400px;
            vertical-align: middle;
            margin-right: 20px;
          }

          >div:nth-child(2) {
            display: inline-block;
            vertical-align: middle;

            >div:nth-child(1) {
              font-size: 24px;
              font-weight: bold;
              color: #E93939;

              >span:nth-child(1) {
                font-size: 40px;
              }

              >span:nth-child(2) {
                text-decoration: line-through;
                color: #AAAAAA;
              }
            }

            >div:nth-child(2) {
              font-size: 26px;
              font-weight: bold;
              color: #666666;
            }
          }

          >img:nth-child(3) {
            width: 40px;
            vertical-align: middle;
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -20px;
          }
        }

        .ad {
          display: flex;
          margin-top: 39px;

          .item {
            flex: 1;
            text-align: center;

            img {
              width: 60px;
              margin-bottom: 10px;
            }

            div {
              font-size: 24px;
              color: #333333;
            }
          }
        }
      }
    }

    .foot {
      height: 120px;
      background: #FFFFFF;
      padding: 15px 30px;
      box-sizing: border-box;

      .btnDiv {
        >div {
          display: inline-block;
          vertical-align: top;
        }

        .left {
          width: 450px;
          height: 90px;
          background: #000000;
          border-radius: 45px 0px 0px 45px;
          box-sizing: border-box;
          padding: 0px 40px;

          .cell {
            height: 29px;
            position: relative;
            top: 15px;

            >div:nth-child(1) {
              color: #fff;
              font-size: 24px;
              font-weight: bold;
              line-height: 24px;

              >span:nth-child(1) {
                font-size: 46px;
              }

              >span:nth-child(2) {
                color: #AAAAAA;
              }
            }

            >div:nth-child(2) {
              font-size: 24px;
              color: #AAAAAA;
            }
          }

        }

        .right {
          width: 240px;
          height: 90px;
          background: #21D0C8;
          font-size: 32px;
          font-weight: 800;
          color: #FFFFFF;
          border-radius: 0px 45px 45px 0px;
          text-align: center;
          line-height: 90px;
        }
      }
    }
  }

</style>
<style>


</style>
